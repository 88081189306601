html {
  font-family: system-ui, sans-serif;
  font-size: 1.25rem;
}

body {
  margin: 0;
}

svg {
  display: inline-block;
  vertical-align: middle !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

table {
  overflow-wrap: "break-word";
}

.map-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.map-wrapper svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-border {
  border: dashed;
  border-radius: 12px;
}
