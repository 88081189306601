img {
  width: 50%;
  max-width: 30vh;
}

.center {
  margin: auto;
  width: 60%;
  border: 5px solid #ffff00;
  padding: 10px;
}
