/* .embed-container {
  position: relative;
  padding-bottom: 80%;
  height: 0;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

.iframe-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
